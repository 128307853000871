import * as React from "react"
import { Helmet } from "react-helmet";
import * as styles from "../style/index.module.scss"

// markup
const IndexPage = () => {
  return (
    <main className={styles.wrap}>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Trocando Figuras</title>
        <meta rel="preload" name="description" content="Um espaço para quem constrói histórias trocando figuras" />
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap" rel="stylesheet"></link>
      </Helmet>
      <h1 className={styles.title}>Trocando Figuras</h1>
      <a className={styles.mail} 
        title="Envier um email para Trocando Figuras" 
        href="contato@trocandofiguras.com.br"
      >
        contato@trocandofiguras.com.br
      </a>
    </main>
  )
}

export default IndexPage
